<template>
    <div class="w-full flex flex-col">
        <!-- <menuBar></menuBar> -->
        <div class="bg-slate-100 min-h[100vh] flex-auto pb-10">
            <subMenus></subMenus>
            <div class="flex flex-col md:grid grid-cols-4 py-5 gap-3 md:max-w-6xl mx-auto px-3">
                <div class="col-span-3 gap-3 grid grid-cols-3">
                    <div class="border border-1 rounded-lg bg-white">
                        <div class="flex flex-col justify-between items-center p-5">
                            <svg class="w-[50px] text-green-700" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            <h2 class="text-2xl text-center w-full">Our Office</h2>
                        </div>
                        <div class="p-5 text-center">
                            Kigali, Rwanda KK 108 ST
                        </div>
                    </div>
                    <div class="border border-1 rounded-lg bg-white">
                        <div class="flex flex-col justify-between items-center p-5">
                            <svg class="w-[50px] text-green-700" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            <h2 class="text-2xl text-center w-full">Our Phone</h2>
                        </div>
                        <div class="p-5 text-center">
                            <a href="tel:+250788828348">+250788828348</a>
                        </div>
                    </div>
                    <div class="border border-1 rounded-lg bg-white">
                        <div class="flex flex-col justify-between items-center p-5">
                            <svg class="w-[50px] text-green-700" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            <h2 class="text-2xl text-center w-full">Our Email</h2>
                        </div>
                        <div class="p-5 text-center">
                            <a href="mailto:vuvuzelarwanda@gmail.com">vuvuzelarwanda@gmail.com</a>
                        </div>
                    </div>
                </div>
                <div class="col-span-3 border border-1 rounded-lg bg-white">
                    <div class="flex justify-between items-center p-5">
                        <h2 class="text-3xl">Contact Us</h2>
                    </div>
                    <div class="p-5">
                        <div class="pb-5">
                            If you have any questions, concerns and challenges. <br>
                            We'd Love to Hear From You
                        </div>
                        <form @submit.prevent="sendMessage">
                <div class="flex  -mx-3 mb-6">
                        <div class="w-full px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                Fullnames
                            </label>
                            <input required class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" v-model="names" type="text" placeholder="Fullnames">
                        </div>
                        <div class="w-full px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                Email Address
                            </label>
                            <input required class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" v-model="email" type="text" placeholder="Email Address">
                        </div>
                    </div>
                    <div class="flex  -mx-3 mb-6">
                        <div class="w-full px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                Message
                            </label>
                            <textarea required rows="6" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" placeholder="Message" v-model="message"></textarea>
                        </div>
                    </div>
                    <button :disabled="isSending" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                        {{isSending?'Sending...':'Send Message'}}
                    </button>
                </form>
                    </div>
                </div>
            </div>
        </div>
        <footerPage></footerPage>
    </div>
</template>

<script>
// import menuBar from './includes/menuBar.vue';
import subMenus from "./includes/subMenus.vue";
import footerPage from "./includes/footerPage.vue";
import axios from 'axios';
        // import apiService from '@/assets/api/apiService'

        import { useHead } from '@vueuse/head'
export default {
  setup() {

    useHead({
      // Can be static or computed
          title: `Vuvuzela | Football news, Live scores, Fixtures & Results`,
      meta: [
        {
          name: `description`,
          content: 'Welcome to Vuvuzela, your ultimate destination for everything football-related! Get the latest football news, live scores, upcoming fixtures, and match results all in one place. Stay updated with breaking news, player transfers, match analyses, and more. Whether you\'re a die-hard fan or a casual follower, Vuvuzela is your go-to source for all things football!',
        },
        ],
     
    })
  },
    data() {
        return {
            names:'',
            email:null,
            message:'',
            isSending:false
        };
    },
    components: {
        // menuBar,
        subMenus,
        footerPage,
    },
    mounted() {
        document.title = 'Contact Us :: Vuvuzela'
    },
    methods: {
        async sendMessage(){
                    const formData = new FormData();
                    formData.append('names', this.names);
                    formData.append('email', this.email);
                    formData.append('message', this.message);
                    if(this.email!=null){
                        this.isSending = true
                    try {
                        const response = await axios.post('https://api.vuvuzela.rw/email/', formData, {
                        headers: {
                        'Content-Type': 'application/json',
                        },
                    });
                        console.log(response.data.message)
                        this.names=''
                        this.email=''
                        this.message=''
                        this.isSending = false
                    } catch (error) {
                        console.error('Error uploading file:', error);
                        this.isSending = false
                    }
                }
            },},
};
</script>

<style scoped>
.content img {
    width: 100% !important;
}
</style>