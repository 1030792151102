import axios  from "./axios.js";
const url = 'https://v3.football.api-sports.io/';
const backend = 'https://api.vuvuzela.rw/api/';
// const backend = 'http://127.0.0.1:5050/api/';
// const url = 'https://api-football-v1.p.rapidapi.com/v3/';

class apiService{
    static async getStatus() {
        let res = await axios.get(url+'status')
        let resp = res.data;
        return resp;
    }
    static async getCountries() {
        let res = await axios.get(url+'countries')
        let resp = res.data;
        return resp;
    }
    static async getSeasons() {
        let res = await axios.get(url+'leagues/seasons')
        let resp = res.data;
        return resp;
    }
    static async getFixtures(params) {
        let res = await axios.get(url+params)
        let resp = res.data;
        return resp;
    }
    static async getData(params) {
        let res = await axios.get(url+params)
        let resp = res.data;
        return resp;
    }

    static async getLeagueByCountry(country) {
        let res = await axios.get(url+'leagues?country='+country)
        let resp = res.data;
        return resp;
    }
    static async adminLogin(data) {
        let res = await axios.post(backend+'login',data)
        let resp = res.data;
        return resp;
    }
    static async saveArticle(data) {
        let res = await axios.post(backend+'article',data)
        let resp = res.data;
        return resp;
    }
    static async deleteArticle(id) {
        let res = await axios.delete(backend+'article/'+id)
        let resp = res.data;
        return resp;
    }
    static async getArticles(limit) {
        let res = await axios.get(backend+'articles/'+limit)
        let resp = res.data;
        return resp;
    }
    static async getArticlesFiltered(limit,cat) {
        let res = await axios.get(backend+'articles/'+cat+'/'+limit)
        let resp = res.data;
        return resp;
    }
    static async getCategories() {
        let res = await axios.get(backend+'categories')
        let resp = res.data;
        return resp;
    }
    static async deleteCategory(id) {
        let res = await axios.delete(backend+'category/'+id)
        let resp = res.data;
        return resp;
    }
    static async getCategory(id) {
        let res = await axios.get(backend+'category/'+id)
        let resp = res.data;
        return resp;
    }
    static async getArticle(id) {
        let res = await axios.get(backend+'article/'+id)
        let resp = res.data;
        return resp;
    }
    static async getRwanda() {
        let res = await axios.get(backend+'rwanda/')
        let resp = res.data;
        return resp;
    }
    static async getRelatedArticles(id,cat) {
        let res = await axios.get(backend+'related/'+id+'/'+cat)
        let resp = res.data;
        return resp;
    }
    static serializeFormData(form) {
        const formData = new FormData(form);
        return new URLSearchParams(formData).toString();
      }
      static realDate(newdate){
          let date = new Date(newdate);
          let year = date.getFullYear();
          let month = String(date.getMonth() + 1).padStart(2, '0');
          let day = String(date.getDate()).padStart(2, '0');
          let newDate = `${year}-${month}-${day}`;
          date = new Date(newDate);
          day = date.getDate();
          month = date.toLocaleString("default", { month: "long" });
          year = date.getFullYear();
          const formattedDate = `${day}${this.getOrdinalSuffix(day)}-${month}-${year}`;
          return formattedDate;
      }
      
      static calendarDate(newdate){
          let date = new Date(newdate);
          let year = date.getFullYear();
          let month = String(date.getMonth() + 1).padStart(2, '0');
          let day = String(date.getDate()).padStart(2, '0');
          let newDate = `${year}-${month}-${day}`;
          return newDate;
      }
      static getOrdinalSuffix(number) {
          const suffixes = ["th", "st", "nd", "rd"];
          const lastDigit = number % 10;
          const suffix = suffixes[lastDigit] || suffixes[0];
          return suffix;
      } 
      static fixtureDate(dateString){

            // Create a Date object from the input string
            const dateObject = new Date(dateString);

            // Extract date components
            // const year = dateObject.getFullYear();
            const month = dateObject.getMonth() + 1; // Months are zero-based, so add 1
            const day = dateObject.getDate();

            // Extract time components
            const hours = dateObject.getHours();
            const minutes = dateObject.getMinutes();
            // const seconds = dateObject.getSeconds();

            const dateGotten = [
                {'date':day+' '+month},
                {'time':hours+' '+minutes}
            ]

            return dateGotten
            // console.log(`Date: ${year}-${month}-${day}`);
            // console.log(`Time: ${hours}:${minutes}:${seconds}`);

      }
}

export default apiService;