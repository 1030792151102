<template>
    <div class="w-full flex flex-col">
        <!-- <menuBar></menuBar> -->
        <div class="bg-slate-100 min-h[100vh] flex-auto pb-10">
            <subMenus></subMenus>
            <div class="flex flex-col md:grid grid-cols-4 py-5 gap-3 md:max-w-6xl mx-auto px-3">
                <div class="col-span-3 border border-1 rounded-lg bg-white">
                    <div class="flex justify-between items-center p-5">
                        <h2 class="text-3xl">Who we are</h2>
                    </div>
                    <div class="p-5">
                        <p>This website is operated by VUVUZELA MEDIA GROUP Ltd (“We”). The leading football news website
                            and number one destination for real time scores for Rwanda Premier League and other most popular
                            leagues and champions all over the world in Football.
                            </p>
                        <p>&nbsp;</p>
                            <p>
                            Up to date tables, fixtures and scores throughout the world live as they happen including the
                            Rwanda Premier League, English Premier League, La Liga, Serie A, Bundesliga, Ligue 1 and
                            Europe’s biggest competitions such as the Champions League and Europa League.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            To contact us, please visit <router-link to="/contact"  class="text-green-700">contact</router-link> or email <a href="mailto:support@vuvuzela.rw" class="text-green-700">support@vuvuzela.rw</a>.
                        </p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
        <footerPage></footerPage>
    </div>
</template>

<script>
// import menuBar from './includes/menuBar.vue';
import subMenus from "./includes/subMenus.vue";
import footerPage from "./includes/footerPage.vue";

import { useHead } from '@vueuse/head'
export default {
  setup() {

    useHead({
      // Can be static or computed
          title: `Vuvuzela | Football news, Live scores, Fixtures & Results`,
      meta: [
        {
          name: `description`,
          content: 'Welcome to Vuvuzela, your ultimate destination for everything football-related! Get the latest football news, live scores, upcoming fixtures, and match results all in one place. Stay updated with breaking news, player transfers, match analyses, and more. Whether you\'re a die-hard fan or a casual follower, Vuvuzela is your go-to source for all things football!',
        },
        ],
     
    })
  },
    data() {
        return {
        };
    },
    components: {
        // menuBar,
        subMenus,
        footerPage,
    },
    mounted() {
        document.title = 'Who we are :: Vuvuzela'
    },
    methods: {},
};
</script>

<style scoped>
.content img {
    width: 100% !important;
}
</style>