<template>
    <div class="w-full flex flex-col">
     <!-- <menuBar></menuBar> -->
    <div class="bg-slate-100 min-h[100vh] flex-auto pb-10">
        <subMenus></subMenus>
        <div class="flex flex-col md:grid grid-cols-4 py-5 gap-3 md:max-w-6xl mx-auto px-3">
            <div class="col-span-3 border border-1 rounded-lg bg-white">
               <div class="flex flex-col md:flex-row gap-3 justify-between items-center p-5">
                   <h2 class="text-2xl">{{ article.title }}</h2>
                   <span class="text-gray-500">{{ setDate(article.datePosted) }}</span>
               </div>
               <img :src="article.thumbnail" alt="" class="w-full">
               <div class="p-5 content" v-html="htmlContent"></div>
               
               <div class="flex flex-col md:flex-row gap-3 justify-between items-center p-5">
                   <span class="text-gray-500">Posted by: {{ user }}</span>
               </div>
               <div class="p-5 flex-col md:flex-row gap-3 flex justify-between">
                   <div class="flex gap-2">
                    <span class="inline-flex items-center justify-center px-2 py-2 whitespace-nowrap text-xs font-bold leading-none text-white bg-slate-700 rounded-full" v-for="tag in tags" :key="tag">{{ tag }}</span>
                   </div>
                   <div class="flex gap-4">
              <a
                target="_blank"
                :href="'https://www.facebook.com/share.php?u=' + curUrl"
                class="bg-blue-500 p-2 font-semibold text-white inline-flex items-center space-x-2 rounded"
              >
                <svg
                  class="w-5 h-5 fill-current"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
                  />
                </svg>
              </a>

              <a
                target="_blank"
                :href="`http://twitter.com/share?&url=${curUrl}&text=${title}&hashtags=${article.tags}`"
                class="bg-blue-400 p-2 font-semibold text-white inline-flex items-center space-x-2 rounded"
              >
                <svg
                  class="w-5 h-5 fill-current"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"
                  />
                </svg>
              </a>
              <a
                target="_blank"
                :href="`https://www.linkedin.com/sharing/share-offsite/?url=${curUrl}`"
                class="bg-blue-600 p-2 font-semibold text-white inline-flex items-center space-x-2 rounded"
              >
                <svg
                  class="w-5 h-5 fill-current"
                  role="img"
                  viewBox="0 0 256 256"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      d="M218.123122,218.127392 L180.191928,218.127392 L180.191928,158.724263 C180.191928,144.559023 179.939053,126.323993 160.463756,126.323993 C140.707926,126.323993 137.685284,141.757585 137.685284,157.692986 L137.685284,218.123441 L99.7540894,218.123441 L99.7540894,95.9665207 L136.168036,95.9665207 L136.168036,112.660562 L136.677736,112.660562 C144.102746,99.9650027 157.908637,92.3824528 172.605689,92.9280076 C211.050535,92.9280076 218.138927,118.216023 218.138927,151.114151 L218.123122,218.127392 Z M56.9550587,79.2685282 C44.7981969,79.2707099 34.9413443,69.4171797 34.9391618,57.260052 C34.93698,45.1029244 44.7902948,35.2458562 56.9471566,35.2436736 C69.1040185,35.2414916 78.9608713,45.0950217 78.963054,57.2521493 C78.9641017,63.090208 76.6459976,68.6895714 72.5186979,72.8184433 C68.3913982,76.9473153 62.7929898,79.26748 56.9550587,79.2685282 M75.9206558,218.127392 L37.94995,218.127392 L37.94995,95.9665207 L75.9206558,95.9665207 L75.9206558,218.127392 Z M237.033403,0.0182577091 L18.8895249,0.0182577091 C8.57959469,-0.0980923971 0.124827038,8.16056231 -0.001,18.4706066 L-0.001,237.524091 C0.120519052,247.839103 8.57460631,256.105934 18.8895249,255.9977 L237.033403,255.9977 C247.368728,256.125818 255.855922,247.859464 255.999,237.524091 L255.999,18.4548016 C255.851624,8.12438979 247.363742,-0.133792868 237.033403,0.000790807055"
                    ></path>
                  </g>
                </svg>
              </a>
              <a
                target="_blank"
                :href="`https://api.whatsapp.com/send?text=${title}: ${curUrl}`"
                class="bg-green-600 p-2 font-semibold text-white inline-flex items-center space-x-2 rounded"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
                  />
                </svg>
              </a>
            </div>
               </div>
            </div>
            <div class="col-span-1">
               <div class=" flex flex-col gap-3 border border-1 rounded-lg">
                   <h2 class="p-5 py-3 text-2xl bg-gray-200">Related Articles</h2>
                    <div class=" p-3 flex flex-col gap-3">
                        <newsThumb @click="loadArticle(article._id)" v-for="article in articles" :key="article" :data="article"></newsThumb>
                    </div>
               </div>
            </div>
        </div>
        <div class="grid grid-cols-4 p-3 gap-3 md:max-w-6xl mx-auto">
            <div class="col-span-4 md:col-span-3 border border-1 rounded-lg bg-white">
               <div class="flex justify-between items-center p-5 border-b">
                   <h2 class="text-2xl">Add Comment</h2>
                   <span class="text-red-500">Please don't use abuse words</span>
               </div>
               <div class="p-5">
                    <form @submit.prevent="saveComment">
                <div class="flex  -mx-3 mb-6">
                        <div class="w-full px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                Username
                            </label>
                            <input required class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" v-model="username" type="text" placeholder="Username">
                        </div>
                        <div class="w-full px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                Email Address
                            </label>
                            <input required class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" v-model="email" type="text" placeholder="Email Address">
                        </div>
                    </div>
                    <div class="flex  -mx-3 mb-6">
                        <div class="w-full px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                Comment
                            </label>
                            <textarea required rows="6" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" placeholder="Comment" v-model="comment"></textarea>
                        </div>
                    </div>
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                        Comment
                    </button>
                </form>
               </div>
            </div>
        </div>
        <div class="grid grid-cols-4 p-3 gap-3 md:max-w-6xl mx-auto">
            <div class="col-span-4 md:col-span-3 border border-1 rounded-lg bg-white">
               <div class="flex justify-between items-center p-5 border-b">
                   <h2 class="text-2xl">Comments</h2>
               </div>
               <div class="border-b p-3 text-sm flex gap-4" v-for="com in article.comments" :key="com">
                    <div>
                        <img :src="`https://ui-avatars.com/api/?rounded=true&background=random&size=40&name=${com.username}`" alt="">
                    </div>
                   <div class="w-full">
                       <div class="flex justify-between">
                            <strong class="text-gray-500">{{ com.username }}</strong>
                            <span class="text-gray-300">{{ setDate(com.timestamp) }}</span>
                        </div>
                        <p>{{ com.commentText }}</p>
                   </div>
               </div>
            </div>
        </div>
    </div>
    <footerPage></footerPage>

    </div>
    </template>
    
    <script>
        // import menuBar from './includes/menuBar.vue';
        import subMenus from './includes/subMenus.vue';
        import newsThumb from './includes/newsThumb.vue';
        import axios from 'axios';
        import apiService from '@/assets/api/apiService'
import footerPage from "./includes/footerPage.vue";

import { useHead } from '@vueuse/head'
export default {
  setup() {

    useHead({
      // Can be static or computed
          title: `Vuvuzela | Football news, Live scores, Fixtures & Results`,
      meta: [
        {
          name: `description`,
          content: 'Welcome to Vuvuzela, your ultimate destination for everything football-related! Get the latest football news, live scores, upcoming fixtures, and match results all in one place. Stay updated with breaking news, player transfers, match analyses, and more. Whether you\'re a die-hard fan or a casual follower, Vuvuzela is your go-to source for all things football!',
        },
        ],
     
    })
  },
            data(){
                return{
                    searchKey:'',
                    htmlContent:'',
                    article:[],
                    articles:[],
                    articleId:'',
                    tags:[],
                    username:'',
                    email:'',
                    comment:'',
                    curUrl:'',
                    title:'',
                    user:''
                }
            },
            components:{
                // menuBar,
                subMenus,
                newsThumb,
                footerPage
            },
            mounted(){
                this.articleId = this.$route.params.id;
                this.loadArticleDetails();
                // apiService.getArticles().then(apiData => {
                //     this.articles = apiData
                // }).catch(error =>{
                //     console.log(error)
                // });
            },
            methods:{
                loadArticle(id){
                    this.articleId = id
                    this.loadArticleDetails()
                },
                loadArticleDetails(){
                    apiService.getArticle(this.articleId).then(apiData => {
                    this.article = apiData
                    this.htmlContent = JSON.parse(apiData.details)
                    this.tags = apiData.tags.split(',')
                    document.title=apiData.title
                    
                    this.curUrl = window.location.href
                    this.title = apiData.title
                    this.user = apiData.admin[0].names
                    apiService.getRelatedArticles(apiData._id,apiData.category[0]._id).then(apiData => {
                        this.articles = apiData
                    }).catch(error =>{
                        console.log(error)
                    });
                    }).catch(error =>{
                        console.log(error)
                    });
                    window.scrollTo(0, 0);
                },
                setDate(date){
                    return apiService.realDate(date)
                },
                async saveComment(){
                    const formData = new FormData();
                    formData.append('username', this.username);
                    formData.append('email', this.email);
                    formData.append('commentText', this.comment);
                    if(this.articleId!=null){
                    try {
                        const response = await axios.post('https://api.vuvuzela.rw/api/article/comment/' + this.articleId, formData, {
                        headers: {
                        'Content-Type': 'multipart/form-data', // Set the Content-Type header to 'multipart/form-data'
                        },
                    });
                        console.log(response.data.message)
                        this.username=''
                        this.email=''
                        this.comment=''
                    } catch (error) {
                        console.error('Error uploading file:', error);
                    }
                }
            },
        }
        }
    </script>
    
    <style scoped>
        .content img{
            width: 100% !important;
        }
    </style>