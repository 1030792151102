<template>
    <div class="w-full flex flex-col">
     <!-- <menuBar></menuBar> -->
    <div class="bg-slate-100 min-h[100vh] flex-auto pb-10">
        <subMenus></subMenus>
        <div class="py-5 mb-0 gap-3 md:max-w-6xl mx-auto relative flex w-full h-[100px] md:h-[200px] overflow-hidden rounded-none md:rounded-b-2xl justify-center items-center">
            <img :src="category.thumbnail" alt="" class="absolute w-full h-full object-cover">
            <h1 class="text-xl md:text-3xl z-20 p-2 md:p-6 bg-white bg-opacity-[.7] rounded-xl md:rounded-3xl">{{category.title}} News</h1>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 py-5 gap-3 md:max-w-6xl mx-auto px-3">
            <newsThumb v-for="article in articles" :key="article" :data="article"></newsThumb>
        </div>
        <div class="flex justify-center gap-3">
            <button @click="loadMore" class="bg-gray-400 rounded-3xl p-2 px-3 text-white cursor-pointer">Load More</button>
        </div>
    
    </div>
    <footerPage></footerPage>

    </div>
    </template>
    
    <script>
        // import menuBar from './includes/menuBar.vue';
        import subMenus from './includes/subMenus.vue';
        import newsThumb from './includes/newsThumb.vue';
        import apiService from '@/assets/api/apiService'
import footerPage from "./includes/footerPage.vue";

import { useHead } from '@vueuse/head'
export default {
  setup() {

    useHead({
      // Can be static or computed
          title: `Vuvuzela | Football news, Live scores, Fixtures & Results`,
      meta: [
        {
          name: `description`,
          content: 'Welcome to Vuvuzela, your ultimate destination for everything football-related! Get the latest football news, live scores, upcoming fixtures, and match results all in one place. Stay updated with breaking news, player transfers, match analyses, and more. Whether you\'re a die-hard fan or a casual follower, Vuvuzela is your go-to source for all things football!',
        },
        ],
     
    })
  },
            data(){
                return{
                    searchKey:'',
                    articles:[],
                    category:{
                        title: '',
                        thumbnail:'./sport.jpg'
                    },
                    limit: 10,
                    toLoad: 10
                }
            },
            components:{
                // menuBar,
                subMenus,
                newsThumb,
                footerPage
            },
            mounted(){
                let cat = this.$route.params.id
                if(typeof cat === 'undefined'){
                    this.loadArticle()
                }else{
                    this.loadArticleFiltered(cat)
                    this.loadCategory(cat)
                }
            },
            methods:{
                loadCategory(cat){
                    apiService.getCategory(cat).then(apiData => {
                    this.category = apiData
                    }).catch(error =>{
                        console.log(error)
                    });
                },
                loadArticle(){
                    apiService.getArticles(this.toLoad).then(apiData => {
                    this.articles = apiData
                    }).catch(error =>{
                        console.log(error)
                    });
                },
                loadArticleFiltered(cat){
                    apiService.getArticlesFiltered(this.toLoad,cat).then(apiData => {
                    this.articles = apiData
                    }).catch(error =>{
                        console.log(error)
                    });
                },
                loadMore(){
                    this.toLoad+=this.limit
                    let cat  = this.$route.params.id;
                if(typeof cat === 'undefined'){
                    this.loadArticle()
                }else{
                    this.loadArticleFiltered(cat)
                }

                }
            },
            watch: {
                $route(to) {
                    this.toLoad = 10;
                    let cat  = to.params.id;
                if(typeof cat === 'undefined'){
                    this.loadArticle()
                    this.category = {
                        title: '',
                        thumbnail:'./sport.jpg'
                    }
                }else{
                    this.loadArticleFiltered(cat)
                    this.loadCategory(cat)
                }
                }
            },
            computed: {
                filteredItems() {
                if (this.searchKey === '') {
                    return this.countries;
                } else {
                    const searchTerm = this.searchKey.toLowerCase();
                    return this.countries.filter(item => item.name.toLowerCase().includes(searchTerm));
                }
                },
            },
        }
    </script>
    
    <style scoped>
        .content img{
            width: 100% !important;
        }
    </style>