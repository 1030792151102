<template>
  <div class="w-full flex flex-col">
    <!-- <menuBar></menuBar> -->
    <div class="bg-slate-100 min-h[100vh] flex-auto pb-10">
      <subMenus></subMenus>
      <div
        class="flex flex-col md:grid grid-cols-4 py-5 gap-3 md:max-w-6xl mx-auto px-3"
      >
        <div class="col-span-3 border border-1 rounded-lg bg-white">
          <div class="flex justify-between items-center p-5">
            <h2 class="text-3xl">Privacy and Policy</h2>
          </div>
          <div class="p-5">
            <p><strong>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;INTRODUCTION</strong></p>
            <p>This privacy notice was last updated on 30 October 2023.</p>
            <p>
              <strong
                >2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IMPORTANT INFORMATION AND WHO
                WE ARE</strong
              >
            </p>
            <p>
              This website is operated by VUVUZELA MEDIA GROUP Ltd
              (“<strong>We</strong>”). The leading football news website and
              number one destination for real time scores for Rwanda Premier
              League and other most popular leagues and champions all over the
              world in Football.
            </p>
            <p>
              VUVUZELA MEDIA GROUP Ltd respects your privacy and is committed to
              protecting your information. This privacy notice will inform you
              as to how we look after your information when you (a) sign-up to
              receive our newsletter(s) or information about our products and
              services; (b) sign-up for any VUVUZELA MEDIA GROUP event or
              competition.
            </p>
            <p>
              This privacy notice applies to your use of our Services
              (regardless of where you visit from or otherwise access our
              Services) and tell you about your privacy rights and how Data
              Protection Laws protect you.
            </p>
            <p>
              <strong
                >3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PURPOSE OF THIS PRIVACY
                NOTICE</strong
              >
            </p>
            <p>
              This privacy notice aims to give you information on how VUVUZELA
              MEDIA GROUP collects, uses, discloses, transfers, stores and
              processes your information when you use our Services, including
              any data you may provide through your use of our Services.
            </p>
            <p>
              It is important that you read this privacy notice together with
              any other privacy notice or fair processing notice we may provide
              on specific occasions when we are collecting or processing
              information about you so that you are fully aware of how and why
              we are using your data. This privacy notice supplements the other
              policies and is not intended to override them.
            </p>
            <p>
              If you have any questions about this privacy notice, please
              contact us, visit
            </p>
            <p>
              <a
                href="https://www.vuvuzela.rw/contact/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>https://www.vuvuzela.rw/contact/</strong></a
              >&nbsp;or email
              <a
                href="mailto:support@vuvuzela.rw"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>support@vuvuzela.rw</strong></a
              >
            </p>
            <p>
              <strong>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;THIRD-PARTY LINKS</strong>
            </p>
            <p>
              Our Platforms may include links to third-party websites, plug-ins
              and applications. Clicking on those links or enabling those
              connections may allow third parties to collect or share data about
              you. We do not control these third-party websites and are not
              responsible for their privacy statements. When you leave our
              Platforms, we encourage you to read the privacy policy of every
              website you visit or third-party mobile application you may use.
            </p>
            <p>
              <strong
                >5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;INFORMATION WE COLLECT ABOUT
                YOU</strong
              >
            </p>
            <p>
              When you use our Services, we may collect Personal Data about you.
            </p>
            <p>
              We have set out below what data we collect and how we collect it.
            </p>
            <p>
              You are under no obligation to provide Personal Data to us.
              However, certain Personal Data is essential for the provision of
              and the quality of the products and services we offer, and you
              might not be able to obtain certain services if you do not provide
              the information required for those services.
            </p>
            <p><strong>Information we do not collect:</strong></p>
            <p>
              We do not collect any information about criminal convictions or
              offences.
            </p>
            <p><strong>So, what information do we collect?</strong></p>
            <p>
              General Location information the device or computer you use to
              access the Site or the Apps will provide us with your IP address.
            </p>
            <p>
              The IP address tells us which city, state or country you are
              accessing the Site or Apps from, but does not give us detailed
              information about your location.
            </p>
            <p>&nbsp;</p>
            <p>Governing Law</p>
            <p>
              The laws of the Country, excluding its conflicts of law rules,
              shall govern this Terms and Your use of the Service. Your use of
              the Application may also be subject to other local, state,
              national, or international laws.
            </p>
            <p>Disputes Resolution</p>
            <p>
              If You have any concern or dispute about the Service, You agree to
              first try to resolve the dispute informally by contacting the
              Company.
            </p>
            <p>
              <strong
                >6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;COOKIES AND OTHER DATA
                COLLECTION TECHNOLOGIES.
              </strong>
            </p>
            <p>
              We, and our service providers, use cookies, scripts, and similar
              technologies to manage the Services and to collect information
              about you and your use of the Services. These technologies help us
              to recognize you, customize or personalize your experience,
              advertise products or services, and analyze the use of our
              Services to make them more useful to you. These technologies may
              also allow us to collect demographic and statistical data and
              provide this information to our service providers to help deliver
              their services.
            </p>
            <p>
              Most internet browsers allow you to remove or manage cookie
              functions and adjust your privacy and security preferences. For
              information on how to do this, access the “help” menu on your
              Internet browser, or access aboutcookies.org. Please note,
              however, that disabling our cookies may mean that you will not be
              able to take full advantage of our Services.
            </p>
            <p>
              <strong>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;GOOGLE ANALYTICS</strong>
            </p>
            <p>
              We use “Google Analytics” to collect information about your use of
              the Website. Google Analytics collects information such as how
              often users access the Services, what pages they access when they
              do so and what other sites they used prior to using the Services.
              Google uses the data collected to track and examine the use of the
              Services to prepare reports on its activities and share them with
              other Google services. To learn more about the use of data
              collection technologies by Google for analytics and to exercise
              choice<strong> </strong>regarding those technologies, please visit
              the Google Analytics Opt-Out browser add-on page.
            </p>
            <p><strong>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;QUESTIONS</strong></p>
            <p>
              If you have any questions about this privacy notice or wish to
              otherwise exercise your rights (such as to withdraw your consent
              from receiving marketing communications), please contact
              us&nbsp;<a
                href="https://www.vuvuzela.rw/contact/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>here.</strong></a
              >
            </p>
            <p>
              <strong
                >9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CHANGES TO THIS PRIVACY
                POLICY</strong
              >
            </p>
            <p>
              We may, from time to time, change or update this privacy notice.
              All changes to this privacy notice will be published on this page
              of our App. Each change will become effective upon publication. We
              recommend that you revisit and read this privacy notice regularly
              to ensure that you are up-to-date with the current terms.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
    <footerPage></footerPage>
  </div>
</template>

<script>
// import menuBar from './includes/menuBar.vue';
import subMenus from "./includes/subMenus.vue";
import footerPage from "./includes/footerPage.vue";

import { useHead } from '@vueuse/head'
export default {
  setup() {

    useHead({
      // Can be static or computed
          title: `Vuvuzela | Football news, Live scores, Fixtures & Results`,
      meta: [
        {
          name: `description`,
          content: 'Welcome to Vuvuzela, your ultimate destination for everything football-related! Get the latest football news, live scores, upcoming fixtures, and match results all in one place. Stay updated with breaking news, player transfers, match analyses, and more. Whether you\'re a die-hard fan or a casual follower, Vuvuzela is your go-to source for all things football!',
        },
        ],
     
    })
  },
  data() {
    return {
    };
  },
  components: {
    // menuBar,
    subMenus,
    footerPage,
  },
  mounted() {
    document.title = 'Privacy and Policy :: Vuvuzela'
  },
  methods: {},
};
</script>

<style scoped>
.content img {
  width: 100% !important;
}
</style>
