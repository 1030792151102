import axios from "axios";
let token = 'abc5e8a2f3de3d041b4bcd4f4ed3219a';
// let token = '0a1f390b43mshe32c945e9a3877cp19b3ffjsn7b991ef342a8';
axios.interceptors.request.use((config) => {
  if (token) {
    config.headers['x-apisports-key'] = token
    // config.headers['X-RapidAPI-Key'] = token
  }
  return config;
});
export default axios;