<template>
    <div>
        <navMenu></navMenu>

        <section class="container px-4  md:max-w-6xl mx-auto mb-10">
    <div class="sm:flex sm:items-center sm:justify-between items-center">
        <h2 class="text-3xl">Add Article</h2>
        <div class="flex items-center mt-4 gap-x-3">

            <router-link to="/admin/" class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600">
                <span>All Articles</span>
            </router-link>
        </div>
    </div>
    <!-- {{ articleData.title }} -->
    
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
        Title
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" v-model="articleData.title" placeholder="Article Titlte">
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
        Category
      </label>
      <select v-model="articleData.category" class="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" placeholder="Article Titlte">
        <option value="">Select Category</option>
        <option v-for="cat in categories" :key="cat" :value="cat._id">{{ cat.title }}</option>
      </select>
    </div>
  </div>
  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
        Cover Photo
      </label>
    <div class="relative rounded-lg overflow-hidden w-full h-[300px] flex justify-center items-center" >
      <img class="z-0 backdrop-blur-md absolute" src="../../../assets/images/pitch.jpg" alt="">
      <img class="z-1 absolute w-full h-full object-cover" v-if="selectedFile || selectedFilePreview" :src="selectedFilePreview" alt="Selected Image">
      <label class="absolute bg-lime-600 px-3 py-2 rounded-3xl text-lg text-white cursor-pointer z-4" for="coverImg">Select Cover</label>
    </div>
    <div>
  </div>
  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
        Article Content
      </label>
    <QuillEditor  :modules="modules" toolbar="full" v-model="contents"></QuillEditor>
    
  <div class="flex flex-wrap -mx-3 mb-6 mt-6">
    <div class="w-full px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
        Tags
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" v-model="articleData.tags" placeholder="Article Tags">
      <p class="text-gray-600 text-xs italic">Please separate each tag with (,)</p>
    </div>
  </div>
  <form @submit.prevent="uploadFile" enctype="multipart/form-data">
      <input type="file" class="opacity-0" ref="fileInput" id="coverImg" @change="handleFileChange">
  <button type="submit" v-if="!sending" class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600">Post Article</button>
    </form>
    </section>
    </div>
</template>

<script>

// import { ref, defineComponent } from 'vue'
import { QuillEditor } from '@vueup/vue-quill'
import BlotFormatter from 'quill-blot-formatter'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import navMenu from './inc/navMenu.vue'
import apiService from '../../../assets/api/apiService'
import axios from 'axios';
    export default {
        components:{
            navMenu,
            QuillEditor,
        },
        data(){
            return{
      selectedFile: null,
      sending: false,
      article:[],
      categories:[],
      message: 'ddd',
                articleData:{
                    details:'',
                    category:'',
                    title:'',
                    tags:''
                },
                articleId: null
            }
        },
        setup: () => {
            const modules = {
            name: 'blotFormatter',  
            module: BlotFormatter, 
            options: {/* options */}
            }
            return { modules }
        },
        mounted(){
          apiService.getCategories().then(apiData => {
              this.categories = apiData
              }).catch(error =>{
                  console.log(error)
              });
          this.articleId = this.$route.params.id;
          if(this.articleId!=null){
            this.loadArticleDetails();
          }else{
            document.title='Add New Article'
          }
        },
        methods:{
          loadArticleDetails(){
                apiService.getArticle(this.articleId).then(apiData => {
                this.article = apiData
                this.htmlContent = JSON.parse(apiData.details)
                this.articleData.title=apiData.title
                this.articleData.category=apiData.category
                this.articleData.tags=apiData.tags
                this.selectedFilePreview=apiData.thumbnail
                document.querySelector('.ql-editor').innerHTML=JSON.parse(apiData.details)
                document.title=apiData.title
                }).catch(error =>{
                    console.log(error)
                });
            },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      this.selectedFilePreview = URL.createObjectURL(this.selectedFile); // Create data URL for preview
    },
    async uploadFile() {
      const formData = new FormData();
        var htmlContent = document.querySelector('.ql-editor').innerHTML;
        // console.log(htmlContent)
        this.articleData.details = JSON.stringify(htmlContent);
      formData.append('title', this.articleData.title);
      formData.append('category', this.articleData.category);
      formData.append('details', this.articleData.details);
      formData.append('tags', this.articleData.tags);
      formData.append('file', this.selectedFile);
      formData.append('user', localStorage.getItem('user'));
        this.sending = true
      if(this.articleId!=null){
      try {
        const response = await axios.put('https://api.vuvuzela.rw/api/article/'+this.articleId, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(response.data.message);
        alert('Article Updated')
        window.location.reload()
      } catch (error) {
        console.error('Error uploading file:', error);
        this.sending = false
      }

      }else{
      try {
        const response = await axios.post('https://api.vuvuzela.rw/api/article', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(response.data.message);
        alert('Article Added')
        window.location.reload()
      } catch (error) {
        console.error('Error uploading file:', error);
        this.sending = false
      }

      }
    }
        }
    }
</script>

<style scoped>

</style>