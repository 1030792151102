<template>
  <div class="w-full flex flex-col">
    <!-- <menuBar></menuBar> -->
    <div class="bg-slate-100 min-h[100vh] flex-auto pb-10">
      <subMenus></subMenus>
      <div class="px-3">
        <div class="md:max-w-6xl my-5 mx-auto overflow-hidden rounded-lg flex object-cover">
          <img src="@/assets/images/ads/top.jpg" class="w-full h-full" alt="" />
        </div>
      </div>
      <div class="flex flex-col md:grid grid-cols-4 pb-5 gap-3 md:max-w-6xl mx-auto px-3 md:px-0">
        <sliderPane></sliderPane>
        <div class="col-span-1 flex justify-between flex-col bg-white rounded-lg overflow-hidden">
          <div class="p-5 flex flex-col gap-4" v-if="leagueData">

            <div class="flex justify-between">
              <div class="flex flex-col">
                <h1 class="mb-0 mt-0 text-base font-medium leading-tight text-primary">
                  {{ leagueData.name }}
                </h1>
                <span class="text-xs">{{ venueData.name }} ({{ venueData.city }})</span>
              </div>

              <div>
                <button type="button" class="bg-red-100 text-red-500 p-3 py-1 text-xs rounded-lg">
                  Live
                </button>
              </div>
            </div>
            <div class="grid grid-cols-3 px-4 gap-3">
              <div class="flex flex-col items-center gap-3">
                <img :src="teamsData.logo" />
                <span class="text-xs">{{ teamsData.name }}</span>
              </div>
              <div class="flex justify-center items-center flex-col">
                <div class="flex gap-3 justify-center items-center text-3xl">
                  <span class="text-blue-700 font-bold">{{
                    goalsData.home
                  }}</span>
                  <span class="text-gray-400 font-bold">:</span>
                  <span class="text-gray-500 font-bold">{{
                    goalsData.away
                  }}</span>
                </div>
                <span class="text-xs">{{ statusData.elapsed }}</span>
                <span class="text-[10px]">{{ statusData.long }}</span>
              </div>
              <div class="flex flex-col items-center gap-3">
                <img :src="teams2Data.logo" />
                <span class="text-xs">{{ teams2Data.name }}</span>
              </div>
            </div>
          </div>
          <div class="text-center p-2 border-t cursor-pointer hover:bg-slate-300 duration-75" @click="isMatch = !isMatch">
            {{ isMatch ? 'Hide' : 'Show' }} Match Details</div>
        </div>
        <div class="overflow-hidden rounded-lg flex object-cover">
          <img src="@/assets/images/ads/square.jpg" class="w-full h-full" alt="" />
        </div>
      </div>
      <div class="flex flex-col md:grid grid-cols-4 gap-3 md:max-w-6xl mx-auto">

        <div class="col-span-3">
          <div class=" rounded-lg mb-5 overflow-hidden" v-if="isMatch">
            <div class="w-full p-3 bg-[#E9ECEF] flex justify-between items-center">Live Match Details

              <button type="button" class="bg-red-100 text-red-500 p-3 py-1 text-xs rounded-lg">
                Live
              </button>
            </div>
            <div id="wg-api-football-game" data-host="v3.football.api-sports.io"
              data-key="abc5e8a2f3de3d041b4bcd4f4ed3219a" :data-id="matchId" data-theme="" data-refresh="15"
              data-show-errors="false" data-show-logos="true">
            </div>
          </div>
          <div class="flex flex-col md:grid grid-cols-3 gap-3">

            <div>
              <div class="flex mb-3 md:mb-0 flex-col bg-white rounded-lg overflow-hidden">
                <div class="flex h-12 bg-slate-200 border-b px-5 items-center">
                  <h3 class="text-sm">Pinned Leagues</h3>
                </div>
                <div class="max-h-[500px] overflow-x-auto">
                  <a :href="`/${country[0][0].league.id}/${country[0][0].seasons.find(season => season.current === true).year}`"
                    @click="getFixtures(country[0][0].league.id)"
                    class="flex items-center gap-3 p-2 border-b border-1 hover:bg-slate-100 hover:text-gray-900 ease-linear transition-all duration-150"
                    v-for="(country, index) in pinnedLeagues" :key="index">
                    <div class="w-10 h-10 rounded-lg overflow-hidden">
                      <img :src="country[0][0].league.logo" :alt="country[0][0].league.name"
                        class="w-full h-full object-cover" />
                    </div>
                    <div class="flex flex-col">
                      <span class="text-xs">{{
                        country[0][0].league.name
                      }}</span>
                      <span class="text-xs">{{
                        country[0][0].country.name
                      }}</span>
                    </div>
                  </a>
                </div>
              </div>

              <div class="hidden md:block my-5 mx-auto overflow-hidden rounded-lg">
                <img src="@/assets/images/ads/square.jpg" class="w-full" alt="" />
              </div>
              <div class="flex flex-col bg-white rounded-lg overflow-hidden">
                <div class="flex h-12 bg-slate-200 border-b px-5 items-center">
                  <h3 class="text-sm">
                    {{ isLeague ? "Leagues" : "Countries" }}
                  </h3>
                </div>
                <div class="w-full p-2 border-b border-1 bg-gray-200">
                  <input v-if="!isLeague" v-model="searchKey"
                    class="w-full px-2 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    type="search" placeholder="Search Country" />
                </div>
                <div v-if="isLeague" class="w-full p-2 border-b border-1 bg-gray-400 text-white"
                  @click="isLeague = false">
                  &larr; Back
                </div>
                <div v-if="!isLeague" class="max-h-[500px] overflow-x-auto">
                  <router-link to="#" @click="getLeagueByCountry(country.name)"
                    class="flex items-center gap-3 p-2 border-b border-1 hover:bg-slate-100 hover:text-gray-900 ease-linear transition-all duration-150"
                    v-for="country in filteredItems" :key="country">
                    <div class="w-10 h-10 rounded-lg overflow-hidden">
                      <img :src="country.flag" :alt="country.name" class="w-full h-full object-cover" />
                    </div>
                    <span class="text-xs">{{ country.name }}</span>
                  </router-link>
                </div>
                <div v-if="isLeague" class="max-h-[700px] overflow-x-auto">
                  <a :href="`/${league.league.id}/${league.seasons.find(season => season.current === true).year}`"
                    @click="getFixtures(league.league.id)"
                    class="flex items-center gap-3 p-2 border-b border-1 hover:bg-slate-100 hover:text-gray-900 ease-linear transition-all duration-150"
                    v-for="league in filteredLeagueItems" :key="league">
                    <div class="w-10 h-10 rounded-lg overflow-hidden">
                      <img :src="league.league.logo" :alt="league.league.name" class="w-full h-full object-cover" />
                    </div>
                    <span class="text-xs">{{ league.league.name }}</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="flex flex-col col-span-2">
              <div :style="{ height: loaded+'px' }" class="overflow-hidden">
                <div class="rounded-lg overflow-x-scroll" id="wg-api-football-games" data-host="v3.football.api-sports.io"
                  data-key="abc5e8a2f3de3d041b4bcd4f4ed3219a" data-date="" :data-league="curLeague" :data-season="curSeason"
                  data-theme="" data-refresh="15" data-show-toolbar="true" data-show-errors="false" data-show-logos="true"
                  data-modal-game="true" data-modal-standings="true" data-modal-show-logos="true"></div>
              </div>
              <div @click="loaded+=toload" class="py-3 px-3 bg-slate-400 rounded-b-lg text-center cursor-pointer">Load More</div>
            </div>
          </div>
        </div>
        <div class="col-span-1 flex flex-col gap-3">
          <newsThumb v-for="article in articles" :key="article" :data="article"></newsThumb>
          <div v-if="rwanda" class="mb-5 mx-auto overflow-hidden rounded-lg">
            <h2 class="p-4 bg-slate-200">{{ rwanda.title }}</h2>
            <img :src="rwanda.thumbnail" class="w-full" :alt="rwanda.title" />
          </div>
        </div>
      </div>
    </div>
    <footerPage></footerPage>
  </div>
</template>

<script>
// import menuBar from './includes/menuBar.vue';
import subMenus from "./includes/subMenus.vue";
import newsThumb from "./includes/newsThumb.vue";
// import daysFilter from "./includes/daysFilter.vue";
import apiService from "@/assets/api/apiService";
import sliderPane from "./includes/sliderPane.vue";
import footerPage from "./includes/footerPage.vue";

window.onload = function () {
  // document.querySelector('.wg_button_toggle').innerHTML('Results')
  var elements = document.getElementsByClassName("wg_button_toggle");
  if (elements.length > 0) {
    elements[2].textContent = "RESULTS";
    elements[3].textContent = "FIXTURES";
  }
  var elements2 = document.getElementsByClassName("wg_info");
  if (elements2.length > 0) {
    elements2[2].textContent = "RESULTS";
    for (var i = 0; i < elements2.length; i++) {
      elements2[i].textContent = "Match Details";
    }
  }
}
import { useHead } from '@vueuse/head'
export default {
  setup() {

    useHead({
      // Can be static or computed
          title: `Vuvuzela | Football news, Live scores, Fixtures & Results`,
      meta: [
        {
          name: `description`,
          content: 'Welcome to Vuvuzela, your ultimate destination for everything football-related! Get the latest football news, live scores, upcoming fixtures, and match results all in one place. Stay updated with breaking news, player transfers, match analyses, and more. Whether you\'re a die-hard fan or a casual follower, Vuvuzela is your go-to source for all things football!',
        },
        ],
     
    })
  },
  data() {
    return {
      searchKey: "",
      searchLeaKey: "",
      leagues: [],
      articles: [],
      isLeague: false,
      matchId: '',
      isLeagueShow: false,
      isTable: false,
      isLive: false,
      countries: [],
      fixtures: [],
      tables: [],
      leagueData: "",
      venueData: "",
      statusData: "",
      teamsData: "",
      teams2Data: "",
      goalsData: "",
      currentDate: new Date(),
      batchSize: 15,
      loadedFixtures: 0,
      loadLeagues: [39, 140, 78, 135, 61, 3, 2, 405],
      pinnedLeagues: {},
      leagueId: "",
      rwanda: [],
      curLeague: '',
      curSeason: '',
      isMatch: false,
      loaded:1500,
      toload:1000
    };
  },
  components: {
    // menuBar,
    subMenus,
    newsThumb,
    // daysFilter,
    footerPage,
    sliderPane,
  },
  mounted() {
    document.title = "Home :: Vuvuzela | Football news, Live scores, Fixtures & Results";

    apiService
      .getCountries()
      .then((apiData) => {
        this.countries = apiData.response;
      })
      .catch((error) => {
        console.log(error);
      });
    apiService
      .getRwanda()
      .then((apiData) => {
        this.rwanda = apiData;
      })
      .catch((error) => {
        console.log(error);
      });
    this.matchId = localStorage.getItem('live')
    if (typeof this.$route.params.season !== 'undefined') {
      this.curLeague = this.$route.params.id;
    }
    if (typeof this.$route.params.id !== 'undefined') {
      this.curSeason = this.$route.params.season;
    }

    apiService
      .getArticles(10)
      .then((apiData) => {
        this.articles = apiData;
      })
      .catch((error) => {
        console.log(error);
      });
    apiService
      .getData("fixtures?live=all")
      .then((apiData) => {
        (this.leagueData = apiData.response[0].league),
          (this.venueData = apiData.response[0].fixture.venue),
          (this.matchId = apiData.response[0].fixture.id),
          (this.statusData = apiData.response[0].fixture.status),
          (this.teamsData = apiData.response[0].teams.home),
          (this.teams2Data = apiData.response[0].teams.away),
          (this.goalsData = apiData.response[0].goals),
          (this.lname = apiData.response[0]);

        const key = "live";
        const newValue = apiData.response[0].fixture.id;

        // Check if the key exists in localStorage
        const existingValue = localStorage.getItem(key);
        if (existingValue === null || existingValue !== newValue) {
          // Set the new value in localStorage
          localStorage.setItem(key, newValue);
          // window.location.reload()
        }
        console.log(existingValue, newValue)
        if (existingValue != newValue) {
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.loadLeagues.forEach((leagueId) => {
      apiService
        .getData("leagues?id=" + leagueId)
        .then((apiData) => {
          if (!this.pinnedLeagues[leagueId]) {
            this.pinnedLeagues[leagueId] = [];
          }
          this.pinnedLeagues[leagueId].push(apiData.response);
          // this.pinnedLeagues.push(apiData.response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  methods: {
    setToday(date) {
      this.currentDate = new Date(date);
      this.isLoaded = false;

      apiService
        .getData("fixtures?date=" + date)
        .then((apiData) => {
          this.fixtures = apiData;
          this.isLeagueShow = true;
          this.isLoaded = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setLive() {
      this.isLive = true;
      this.isLoaded = false;
      apiService
        .getData("fixtures?live=all")
        .then((apiData) => {
          this.fixtures = apiData;
          this.isLeagueShow = true;
          this.isLoaded = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setFixture(league) {
      console.log(league);
      this.isLeagueShow = true;
      this.isTable = false;
    },
    setTable(league) {
      this.isLoaded = false;
      apiService
        .getData(`standings?league=${league}&season=2023`)
        .then((apiData) => {
          this.tables = apiData.response[0];
          this.isLeagueShow = false;
          this.isTable = true;
          this.isLoaded = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getLeagueByCountry(country) {
      apiService
        .getLeagueByCountry(country)
        .then((apiData) => {
          this.leagues = apiData;
          this.isLeague = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFixtures(param) {
      this.isLoaded = false;
      this.leagueId = param;
      apiService
        .getFixtures(`fixtures?league=${param}&season=2023`)
        .then((apiData) => {
          this.fixtures = apiData;
          this.isLeagueShow = true;
          this.isLoaded = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFixDate(date) {
      this.isLoaded = false;
      return apiService.fixtureDate(date);
    },
    readableDate(originalDate) {
      const dateObj = new Date(originalDate);
      // const year = dateObj.getFullYear();
      const month = dateObj.toLocaleString("default", { month: "short" });
      const day = dateObj.getDate().toString().padStart(2, "0");
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");

      return {
        date: `${day} ${month}`,
        time: `${hours}:${minutes}`,
      };
    },
    getSortedFixtures(startDate) {
      const fixturesArray = Array.isArray(this.fixtures.response)
        ? this.fixtures.response
        : [];

      // Sort and filter fixtures by the date and time components
      const sortedAndFilteredFixtures = fixturesArray
        .slice()
        .sort((a, b) => {
          const dateA = new Date(a.fixture.date);
          const dateB = new Date(b.fixture.date);

          // Compare both date and time components
          if (dateA.toISOString() === dateB.toISOString()) {
            return 0;
          }

          return dateA > dateB ? 1 : -1;
        })
        .filter((fixture) => new Date(fixture.fixture.date) >= startDate);
      return sortedAndFilteredFixtures;
    },
    loadMoreFixtures() {
      this.loadedFixtures += this.batchSize;
    },
  },
  computed: {
    filteredItems() {
      if (this.searchKey === "") {
        return this.countries;
      } else {
        const searchTerm = this.searchKey.toLowerCase();
        return this.countries.filter((item) =>
          item.name.toLowerCase().includes(searchTerm)
        );
      }
    },
    filteredLeagueItems() {
      if (this.searchLeaKey === "") {
        return this.leagues.response;
      } else {
        const searchTerm = this.searchLeaKey.toLowerCase();
        return this.leagues.response.filter((item) =>
          item.name.toLowerCase().includes(searchTerm)
        );
      }
    },

    todayDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    sortedFixtureList() {
      return this.getSortedFixtures(this.currentDate).slice(
        0,
        this.loadedFixtures + this.batchSize
      );
      // return this.getSortedFixtures(this.currentDate);
    },
    hasMoreFixtures() {
      return (
        this.loadedFixtures < this.getSortedFixtures(this.currentDate).length
      );
    },
  },
};
</script>

<style>
:root {
  --color-red: #fad201 !important;
  --color-green: #15803d !important;
}
</style>