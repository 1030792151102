<template>
    <div>
        <navMenu></navMenu>

        <section class="container px-4  md:max-w-6xl mx-auto mb-10">
    <div class="sm:flex sm:items-center sm:justify-between items-center">
        <h2 class="text-3xl">{{article!=''?'Edit':'Add'}} User</h2>
        <div class="flex items-center mt-4 gap-x-3">
<!-- 
            <router-link to="/admin/" class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600">
                <span>Categories</span>
            </router-link> -->
        </div>
    </div>
    <!-- {{ articleData.title }} -->
    
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
        Name
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" v-model="articleData.names" placeholder="Category Name">
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
        Email
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" v-model="articleData.email" placeholder="User Email">
    </div>
  </div>
  
  
    <div>
  </div>
  
  <button v-on:click="sendData" type="submit" class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600">Save User</button>
    <h2 class="text-3xl">Users</h2>


    <div class="flex flex-col mt-6">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead class="bg-gray-50 dark:bg-gray-800">
                            <tr>
                                <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    #
                                </th>
                                <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    Name
                                </th>
                                <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    Email
                                </th>
                                <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    Type
                                </th>
                                <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                            <tr v-for="(article, index) in categories" :key="article">
                                <td class="px-4 py-4 text-sm font-medium text-white whitespace-nowrap">
                                    {{ index+1 }}
                                </td>
                                <td class="px-12 py-4 text-sm font-medium text-white">
                                    {{ article.names }}
                                </td>

                                <td class="px-12 py-4 text-sm font-medium text-white">
                                    {{ article.email }}
                                </td>
                                <td class="px-12 py-4 text-sm font-medium text-white">
                                    {{ article.type }}
                                </td>

                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div class="flex gap-2">
                                        <router-link :to="`/admin/category/`+article._id">
                                            <svg class="w-5 text-white cursor-pointer" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </router-link>
                                        <svg class="w-5 text-white cursor-pointer" @click="deleteUser(article._id)" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    </section>
    
    </div>
</template>

<script>

import navMenu from './inc/navMenu.vue'
import apiService from '../../../assets/api/apiService'
import axios from 'axios';
    export default {
        components:{
            navMenu,
        },
        data(){
            return{
                selectedFile: null,
                article:[],
                categories:[],
                backend : 'https://api.vuvuzela.rw/api/',

                message: '',
                articleData:{
                    email:'',
                    names:'',
                    type:'user',
                    password:'user@123'
                },
                articleId: null
            }
        },
        mounted(){

          this.articleId = this.$route.params.id;
          if(this.articleId!=null){
            this.loadUsers();
          }else{
            document.title='Add New User'

          }
          this.loadUsers();

        },
        methods:{
          sendData(){
            
           axios.post(this.backend+'user/',this.articleData).then(apiData => {
            console.log(apiData)

            alert('User Added')
            window.location.reload()
                      }).catch(error =>{
                          console.log(error)
                      });
          },
          loadUsers(){
                    apiService.getUsers(this.toLoad).then(apiData => {
                    this.categories = apiData
                    }).catch(error =>{
                        console.log(error)
                    });
                },
              deleteUser(id){
                  var conf = confirm('Do you want to delete');
                  if(conf){
                      
                axios.delete(this.backend+'user/'+id).then(apiData => {
                  console.log(apiData)

                  alert('User Deleted')
                  window.location.reload()
                      }).catch(error =>{
                          console.log(error)
                      });
                  }
              },
              
          loadCategoryDetails(){
                apiService.getCategory(this.articleId).then(apiData => {
                this.article = apiData
                this.articleData.title=apiData.title
                this.selectedFilePreview=apiData.thumbnail
                document.title=apiData.title+ ' Edit'
                }).catch(error =>{
                    console.log(error)
                });
            },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      this.selectedFilePreview = URL.createObjectURL(this.selectedFile); // Create data URL for preview
    },
    async uploadFile() {
      const formData = new FormData();
      formData.append('title', this.articleData.title);
      formData.append('file', this.selectedFile);
      if(this.articleId!=null){
      try {
        // 24.199.122.80
        const response = await axios.put('https://api.vuvuzela.rw/api/category/'+this.articleId, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(response.data.message);
      } catch (error) {
        console.error('Error uploading file:', error);
      }

      }else{
      try {
        const response = await axios.post('https://api.vuvuzela.rw/api/category', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(response.data.message);
        alert('Category Added')
        window.location.reload()
      } catch (error) {
        console.error('Error uploading file:', error);
      }

      }
    }
        }
    }
</script>

<style scoped>

</style>