<template>
    <a :href="`/article/`+data._id" class="flex flex-end">
        <div class="w-full h-48 rounded-lg overflow-hidden bg-gray-300 flex relative">
            <img :src="data.thumbnail" class="w-full h-full object-cover" alt="">
            <h3 class="absolute bottom-0 p-4 text-gray-900 bg-gradient-to-b from-transparent to-gray-200 w-full">{{data.title}}</h3>
        </div>
    </a>
</template>

<script>
    export default {
        props:{
            data:Object
        }
    }
</script>

<style scoped>

</style>