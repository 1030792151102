import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import './assets/css/app.css'
import './registerServiceWorker'
import {createHead} from '@vueuse/head'
const app = createApp(App)
const head = createHead()
app.use(router).use(head)
app.mount('#app')
