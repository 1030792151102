<template>
  <Carousel :autoplay="5000" :wrap-around="true" class="w-full col-span-2  rounded-lg overflow-hiddens">
    <Slide v-for="slide in articles" :key="slide">
      <div class="carousel__item col-span-2 bg-green-600 rounded-lg overflow-hidden flex justify-bottom items-bottom relative w-full h-[280px] object-cover">
        <img :src="slide.thumbnail" alt="" class="w-full h-full object-cover">
        <router-link :to="`/article/${slide._id}`" class="absolute bottom-0 p-4 text-xl text-white bg-gray-800 bg-opacity-[.3] w-full">{{slide.title}}</router-link>
      </div>
    </Slide>

    <template #addons>
      <Navigation />
      <!-- <Pagination /> -->
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel,Navigation, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'
import apiService from "@/assets/api/apiService";

export default defineComponent({
  name: 'AutoPlay',
  data(){
    return{
      articles:[]
    }
  },
  components: {
    Carousel,
    Slide,
    // Pagination,
    Navigation,
  },
  mounted(){
    
    apiService.getArticles(10)
      .then((apiData) => {
        this.articles = apiData;
      })
      .catch((error) => {
        console.log(error);
      });
  }
})
</script>
