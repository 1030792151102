<template>
  <div class="bg-gray-50 border-b sticky top-0 z-10 md:px-16">
    <div class="h-16 md:max-w-6xl mx-auto flex items-center justify-between md:justify-normal">
      <a href="/" class="h-16 flex gap-3 items-center">
        <div class="h-[100%] p-3">
          <img src="@/assets/logo.png" alt="" class="h-[100%]" />
        </div>
        <h1 class="text-3xl text-green-700 we">Vuvuzela</h1>
      </a>
      <svg v-if="!isOpen" @click="isOpen=!isOpen" class="block md:hidden w-[50px] pr-3 cursor-pointer hover:text-green-700  transition ease-in-out" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
        <svg v-if="isOpen" @click="isOpen=!isOpen" class="w-[50px] pr-3 cursor-pointer hover:text-green-700  transition ease-in-out"  aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
      <div class="hidden md:flex  md:pl-10">
          <!-- <a
            href="/"
            class="mdMenu"
          >
            <span class="text-xs">Home</span>
          </a> -->
          <a
            href="/news"
            class="mdMenu"
          >
            <span class="text-xs">News</span>
          </a>
          <a
            :href="`/news/${cat._id}`"
            v-for="cat in categories"
            :key="cat"
            class="mdMenu"
          >
            <span class="text-xs">{{ cat.title }}</span>
          </a>
      </div>
    </div>
    <div v-if="isOpen" class="flex flex-col items-start md:hidden border-t">
          <a
            href="/"
            class="mobiMenu"
          >
            <span class="text-xs">Home</span>
          </a>
          <div
            class="mobiMenu"
          >
            <span class="text-xs">Live</span>
          </div>
          <a
            href="/news"
            class="mobiMenu"
          >
            <span class="text-xs">News</span>
          </a>
          <a
            :href="`/news/${cat._id}`"
            v-for="cat in categories"
            :key="cat"
            class="mobiMenu"
          >
            <span class="text-xs">{{ cat.title }}</span>
          </a>
      </div>
  </div>
</template>

<script>
import apiService from "@/assets/api/apiService";
export default {
  data() {
    return {
      categories: [],
      isOpen:false
    };
  },
  mounted() {
    apiService
      .getCategories()
      .then((apiData) => {
        this.categories = apiData;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped></style>
