import { createRouter,createWebHistory } from "vue-router";
import homePage from '@/components/pages/homePage'
import loginPage from '@/components/pages/admin/loginPage'
import adminHome from '@/components/pages/admin/homePage'
import articlePage from '@/components/pages/admin/articlePage'
import categoryPage from '@/components/pages/admin/categoryPage'
import rwandaPage from '@/components/pages/admin/rwandaPage'
import usersPage from '@/components/pages/admin/usersPage'
import SinglearticlePage from '@/components/pages/articlePage'
import articlesPage from '@/components/pages/articlesPage'
import privacyPage from '@/components/pages/privacyPage'
import termsPage from '@/components/pages/termsPage'
import aboutPage from '@/components/pages/aboutPage'
import contactPage from '@/components/pages/contactPage'
const routes = [
    {
        name: 'Home',
        path:'/',
        component: homePage
    },
    {
        name: 'HomeGame',
        path:'/:id/:season',
        component: homePage,
        props: true
    },
    {
        name: 'Login',
        path:'/admin/login',
        component: loginPage
    },
    {
        name: 'HomeAdmin',
        path:'/admin',
        component: adminHome
    },
    {
        name: 'articlePage',
        path:'/admin/article/',
        component: articlePage,
    },
    {
        name: 'categoryPage',
        path:'/admin/category/',
        component: categoryPage,
    },
    {
        name: 'usersPage',
        path:'/admin/users/',
        component: usersPage,
    },
    {
        name: 'rwandaPage',
        path:'/admin/rwanda/',
        component: rwandaPage,
    },
    {
        name: 'categoryPageEdit',
        path:'/admin/category/:id',
        component: categoryPage,
    },
    {
        name: 'delearticlePage',
        path:'/admin/article/:id',
        component: articlePage,
        props: true
    },
    {
        name: 'SinglearticlePage',
        path:'/article/:id',
        component: SinglearticlePage,
        props: true
    },
    {
        name: 'articlesPage',
        path:'/news/',
        component: articlesPage
    },
    {
        name: 'articlesPageSort',
        path:'/news/:id',
        component: articlesPage
    },
    {
        name: 'provacyPage',
        path:'/privacy/',
        component: privacyPage
    },
    {
        name: 'aboutPage',
        path:'/about/',
        component: aboutPage
    },
    {
        name: 'contactPage',
        path:'/contact/',
        component: contactPage
    },
    {
        name: 'termsPage',
        path:'/terms/',
        component: termsPage
    },
]

const router = Router();
export default router;
function Router(){
    const router = new createRouter({
        history: createWebHistory(),
        routes,
    });
    return router;
}