<template>
    <div>
        <navMenu></navMenu>

        <section class="container px-4  md:max-w-6xl mx-auto mb-10">
    <div class="sm:flex sm:items-center sm:justify-between items-center">
        <h2 class="text-3xl">{{article!=''?'Edit':'Add'}} Rwanda Premier League </h2>
        <div class="flex items-center mt-4 gap-x-3">

            <router-link to="/admin/" class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600">
                <span>Categories</span>
            </router-link>
        </div>
    </div>
    
  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
        Cover Photo
      </label>
    <div class="relative rounded-lg overflow-hidden w-full h-[300px] flex justify-center items-center" >
      <img class="z-0 backdrop-blur-md absolute" src="../../../assets/images/pitch.jpg" alt="">
      <img class="z-1 absolute w-full h-full object-cover" v-if="selectedFile || selectedFilePreview" :src="selectedFilePreview" alt="Selected Image">
      <label class="absolute bg-lime-600 px-3 py-2 rounded-3xl text-lg text-white cursor-pointer z-4" for="coverImg">Select Cover</label>
    </div>
    <div>
  </div>
  

  <form @submit.prevent="uploadFile" enctype="multipart/form-data">
      <input type="file" class="opacity-0" ref="fileInput" id="coverImg" @change="handleFileChange">
  <button type="submit" class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600">Save Category</button>
    </form>
    </section>
    
    </div>
</template>

<script>

import navMenu from './inc/navMenu.vue'
import apiService from '../../../assets/api/apiService'
import axios from 'axios';
    export default {
        components:{
            navMenu,
        },
        data(){
            return{
      selectedFile: null,
      article:[],
      categories:[],
      message: 'ddd',
                articleData:{
                    details:'',
                    title:'',
                    tags:''
                },
                articleId: null
            }
        },
        mounted(){

          this.articleId = this.$route.params.id;
          if(this.articleId!=null){
            this.loadCategoryDetails();
          }else{
            document.title='Add New Article'

          }
          this.loadCategories()

        },
        methods:{
            
            loadCategories(){
                    apiService.getCategories(this.toLoad).then(apiData => {
                    this.categories = apiData
                    }).catch(error =>{
                        console.log(error)
                    });
                },
                deleteCategory(id){
                    var conf = confirm('Do you want to delete');
                    if(conf){
                        apiService.deleteCategory(id).then(apiData => {
                        this.categories = apiData
                        this.loadCategories()
                        }).catch(error =>{
                            console.log(error)
                        });
                    }
                },
          loadCategoryDetails(){
                apiService.getCategory(this.articleId).then(apiData => {
                this.article = apiData
                this.articleData.title=apiData.title
                this.selectedFilePreview=apiData.thumbnail
                document.title=apiData.title+ ' Edit'
                }).catch(error =>{
                    console.log(error)
                });
            },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      this.selectedFilePreview = URL.createObjectURL(this.selectedFile); // Create data URL for preview
    },
    async uploadFile() {
      const formData = new FormData();
      formData.append('title', this.articleData.title);
      formData.append('file', this.selectedFile);
      
      try {
        const response = await axios.put('https://api.vuvuzela.rw/api/rwanda', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(response.data.message);
      } catch (error) {
        console.error('Error uploading file:', error);
      }

      }
    }
        }
</script>

<style scoped>

</style>